import React, {useEffect, useState} from "react";
import DisplayContent from "../../../../../components/displayContent/displayContent";
import "./Rubric.css";

const Rubric = ({question, index, onMarkChange, allocatedMarks}) => {
    // Helper function to get the composite key for allocated marks
    const getCompositeKey = (type, id) => `${type}_${id}`;

    const [subQuestionMarks, setSubQuestionMarks] = useState(
        question.sub_questions?.map((sub) => allocatedMarks[getCompositeKey("subquestion", sub.id)]?.allocated_marks || 0) || [] // Default to 0
    );

    const [nestedSubQuestionMarks, setNestedSubQuestionMarks] = useState(
        question.sub_questions?.map((sub) =>
            sub.nested_sub_questions?.map((nested) => allocatedMarks[getCompositeKey("nested_subquestion", nested.id)]?.allocated_marks || 0) || [] // Default to 0
        ) || []
    );

    const hasAllocatedMarks = () => {
        if (allocatedMarks[getCompositeKey("question", question.id)]?.allocated_marks > 0) {
            return true;
        }

        for (const subQuestion of question.sub_questions || []) {
            if (allocatedMarks[getCompositeKey("subquestion", subQuestion.id)]?.allocated_marks > 0) {
                return true;
            }
            for (const nestedSubQuestion of subQuestion.nested_sub_questions || []) {
                if (allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks > 0) {
                    return true;
                }
            }
        }

        return false;
    };

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipMessage, setTooltipMessage] = useState("");
    const [isAllocated, setIsAllocated] = useState(false);
    const [isQuestionTextVisible, setIsQuestionTextVisible] = useState(false);

    // Reset allocation state when the question or allocatedMarks changes
    useEffect(() => {
        setIsAllocated(hasAllocatedMarks());
    }, [question, allocatedMarks]);


    // Function to show tooltip with a message
    const showTooltip = (message) => {
        setTooltipMessage(message);
        setTooltipVisible(true);

        setTimeout(() => {
            setTooltipVisible(false);
        }, 3000); // Tooltip disappears after 3 seconds
    };

    // Function to check if all nested sub-questions for a given sub-question are marked
    const areAllNestedSubQuestionsMarked = (subQuestion) => {
        return subQuestion.nested_sub_questions?.every(
            (nestedSubQuestion) => allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks > 0
        );
    };

    // Function to handle mark selection via chips for nested sub-questions
    const handleNestedChipMarkSelect = (mark, subIndex, nestedIndex) => {
        const newMarks = [...nestedSubQuestionMarks];
        newMarks[subIndex][nestedIndex] = mark;
        setNestedSubQuestionMarks(newMarks);

        const nestedSubQuestionId = question.sub_questions[subIndex].nested_sub_questions[nestedIndex].id;
        onMarkChange(nestedSubQuestionId, "nested_subquestion", mark); // Pass the selected mark to parent
    };

    const handleNestedSubQuestionMarksChange = (event, subIndex, nestedIndex) => {
        const inputMarks = parseFloat(event.target.value || 0); // Default to 0 if input is empty
        const maxNestedMarks = parseFloat(question.sub_questions[subIndex].nested_sub_questions[nestedIndex].marks || 0);

        if (inputMarks <= maxNestedMarks) {
            const newMarks = [...nestedSubQuestionMarks];
            newMarks[subIndex][nestedIndex] = inputMarks;
            setNestedSubQuestionMarks(newMarks);

            // Pass the updated marks to the parent component
            onMarkChange(
                question.sub_questions[subIndex].nested_sub_questions[nestedIndex].id,
                "nested_subquestion",
                inputMarks
            );
        } else {
            showTooltip(`Nested sub-question ${index + 1}.${subIndex + 1}.${nestedIndex + 1} cannot exceed ${maxNestedMarks} marks.`);
        }
    };

    const handleSubQuestionMarksChange = (mark, subIndex) => {
        const inputMarks = parseFloat(mark || 0); // Default to 0 if input is empty
        const maxSubMarks = parseFloat(question.sub_questions[subIndex].marks || 0);

        if (inputMarks <= maxSubMarks) {
            const newMarks = [...subQuestionMarks];
            newMarks[subIndex] = inputMarks;
            setSubQuestionMarks(newMarks);

            // Pass the updated marks to the parent component
            onMarkChange(question.sub_questions[subIndex].id, "subquestion", inputMarks);
        } else {
            showTooltip(`Sub-question ${index + 1}.${subIndex + 1} cannot exceed ${maxSubMarks} marks.`);
        }
    };

    // Calculate total allocated marks for the question
    const totalAllocatedMarks = question.sub_questions?.reduce((total, subQuestion) => {
        if (subQuestion.nested_sub_questions?.length) {
            return total + subQuestion.nested_sub_questions.reduce((nestedTotal, nestedSubQuestion) => {
                return nestedTotal + (allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks || 0);
            }, 0);
        }
        return total + (allocatedMarks[getCompositeKey("subquestion", subQuestion.id)]?.allocated_marks || 0);
    }, 0);

    const handleAllocateFullMarks = () => {
        if (isAllocated) {
            // Unallocate all marks
            question.sub_questions?.forEach((subQuestion) => {
                onMarkChange(subQuestion.id, "subquestion", 0);
                subQuestion.nested_sub_questions?.forEach((nestedSubQuestion) => {
                    onMarkChange(nestedSubQuestion.id, "nested_subquestion", 0);
                });
            });
        } else {
            // Allocate full marks
            question.sub_questions?.forEach((subQuestion) => {
                onMarkChange(subQuestion.id, "subquestion", parseFloat(subQuestion.marks));
                subQuestion.nested_sub_questions?.forEach((nestedSubQuestion) => {
                    onMarkChange(nestedSubQuestion.id, "nested_subquestion", nestedSubQuestion.marks);
                });
            });
        }
        setIsAllocated(!isAllocated); // Toggle allocation state
    };

    const handleToggleQuestionText = () => {
        setIsQuestionTextVisible((prev) => !prev);
    };

    const handleHalfMarkToggle = (subQuestionIndex, nestedSubQuestionIndex = null) => {
        let currentMarks;

        if (nestedSubQuestionIndex !== null) {
            // Nested sub-question
            const nestedSubQuestionId = question.sub_questions[subQuestionIndex].nested_sub_questions[nestedSubQuestionIndex].id;
            currentMarks = allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestionId)]?.allocated_marks || 0;

            // Prevent exceeding the max marks
            const maxMarks = question.sub_questions[subQuestionIndex].nested_sub_questions[nestedSubQuestionIndex].marks;
            const newMarks = currentMarks % 1 === 0.5 ? Math.floor(currentMarks) : currentMarks + 0.5;
            if (newMarks <= maxMarks) {
                onMarkChange(nestedSubQuestionId, "nested_subquestion", newMarks);
            }
        } else {
            // Sub-question
            const subQuestionId = question.sub_questions[subQuestionIndex].id;
            currentMarks = allocatedMarks[getCompositeKey("subquestion", subQuestionId)]?.allocated_marks || 0;

            // Prevent exceeding the max marks
            const maxMarks = question.sub_questions[subQuestionIndex].marks;
            const newMarks = currentMarks % 1 === 0.5 ? Math.floor(currentMarks) : currentMarks + 0.5;
            if (newMarks <= maxMarks) {
                onMarkChange(subQuestionId, "subquestion", newMarks);
            }
        }
    };


    return (
        <div className="rubric-container" style={{position: "relative"}}>
            <h3 className="rubric-question-headline" style={{display: "flex", alignItems: "center", gap: "10px"}}>
                QUESTION {index + 1} ({totalAllocatedMarks} / {question.marks})
                <button
                    onClick={handleToggleQuestionText}
                    style={{
                        padding: "4px 8px",
                        backgroundColor: "#007BFF",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        fontSize: "14px",
                    }}
                >
                    {isQuestionTextVisible ? "View Answers Only" : "View Questions"}
                </button>
                <button
                    onClick={handleAllocateFullMarks}
                    style={{
                        padding: "4px 8px",
                        backgroundColor: isAllocated ? "rgb(71,191,255)" : "#46bfbf",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        fontSize: "14px",
                    }}
                >
                    {isAllocated ? "Clear All Marks" : "Allocate Full Marks"}
                </button>
            </h3>
            {isQuestionTextVisible && question.instructions && (
                <div className="rubric-question-instructions">
                    <DisplayContent content={question.instructions}/>
                </div>
            )}


            {question.sub_questions?.map((subQuestion, subQuestionIndex) => {
                const allNestedMarked = areAllNestedSubQuestionsMarked(subQuestion);
                const isSubQuestionMarked = allocatedMarks[getCompositeKey("subquestion", subQuestion.id)]?.allocated_marks > 0 || allNestedMarked;

                // Check if the sub-question has nested sub-questions
                if (subQuestion.nested_sub_questions?.length > 0) {
                    return (
                        <div
                            key={subQuestionIndex}
                            className={"rubric-sub-question-container ${isSubQuestionMarked ? 'marked' : ''}"}
                        >
                            {subQuestion.nested_sub_questions.map((nestedSubQuestion, nestedSubQuestionIndex) => (
                                <div
                                    key={nestedSubQuestionIndex}
                                    className={`rubric-nested-question-container ${
                                        allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks > 0 ? 'marked' : ''
                                    }`}
                                >
                                    {/* Flexbox for label and question text alignment */}
                                    <div style={{display: "flex", alignItems: "flex-start", gap: "10px"}}>
                                        <label style={{marginRight: "10px"}}>
                                            {index + 1}.{subQuestionIndex + 1}.{nestedSubQuestionIndex + 1}
                                        </label>
                                        <div style={{flex: 1}}>
                                            {isQuestionTextVisible &&
                                                <DisplayContent content={nestedSubQuestion.text}/>}
                                            {!isQuestionTextVisible && nestedSubQuestion.nested_sub_question_memo && (
                                                <DisplayContent
                                                    content={nestedSubQuestion.nested_sub_question_memo.text}/>
                                            )}
                                        </div>
                                    </div>

                                    {/* Chips or input field rendered below the flexbox */}
                                    {nestedSubQuestion.marks <= 10 ? (
                                        <div className="mark-chips">
                                            {[...Array(Math.ceil(nestedSubQuestion.marks) + 1).keys()].map((mark) => (
                                                <div
                                                    key={mark}
                                                    className={`chip ${
                                                        parseInt(allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks) === mark ? 'selected' : ''
                                                    }`}
                                                    onClick={() => handleNestedChipMarkSelect(mark, subQuestionIndex, nestedSubQuestionIndex)}
                                                >
                                                    {mark}
                                                </div>
                                            ))}

                                            <div
                                                className={`chip ${
                                                    allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks % 1 === 0.5 ? 'selected' : ''
                                                }`}
                                                onClick={() => handleHalfMarkToggle(subQuestionIndex, nestedSubQuestionIndex)}
                                            >
                                                +½
                                            </div>
                                        </div>
                                    ) : (
                                        <input
                                            type="number"
                                            value={allocatedMarks[getCompositeKey("nested_subquestion", nestedSubQuestion.id)]?.allocated_marks || 0}
                                            onChange={(e) => handleNestedSubQuestionMarksChange(e, subQuestionIndex, nestedSubQuestionIndex)}
                                            className="rubric-marks-input"
                                            placeholder={`Max: ${nestedSubQuestion.marks}`}
                                        />
                                    )}

                                    {/* Add a line between nested sub-questions */}
                                    {nestedSubQuestionIndex < subQuestion.nested_sub_questions.length - 1 && (
                                        <hr style={{margin: "10px 0", border: "0.5px solid #ccc"}}/>
                                    )}
                                </div>
                            ))}
                        </div>
                    );
                } else {
                    // Handle sub-questions without nested sub-questions
                    return (
                        <div
                            key={subQuestionIndex}
                            className={`rubric-sub-question-container ${isSubQuestionMarked ? 'marked' : ''}`}
                        >
                            <div style={{display: "flex", alignItems: "flex-start", gap: "10px"}}>
                                <label style={{fontSize: "16px"}}>
                                    {index + 1}.{subQuestionIndex + 1}
                                </label>
                                <div style={{flex: 1}}>
                                    {isQuestionTextVisible ? (
                                        <DisplayContent content={subQuestion.text}/>
                                    ) : (
                                        subQuestion.sub_question_memo && (
                                            <DisplayContent content={subQuestion.sub_question_memo.text}/>
                                        )
                                    )}
                                </div>
                            </div>

                            {/* Chips or input field rendered below */}
                            {subQuestion.marks <= 5 ? (
                                    <div className="mark-chips">
                                        {[...Array(Math.ceil(subQuestion.marks) + 1).keys()].map((mark) => (
                                            <div
                                                key={mark}
                                                className={`chip ${
                                                    parseInt(allocatedMarks[getCompositeKey("subquestion", subQuestion.id)]?.allocated_marks) === mark ? 'selected' : ''
                                                }`}
                                                onClick={() => handleSubQuestionMarksChange(mark, subQuestionIndex)}
                                            >
                                                {mark}
                                            </div>
                                        ))}
                                        <div
                                            className={`chip ${
                                                allocatedMarks[getCompositeKey("subquestion", subQuestion.id)]?.allocated_marks % 1 === 0.5 ? 'selected' : ''
                                            }`}
                                            onClick={() => handleHalfMarkToggle(subQuestionIndex)}
                                        >
                                            +½
                                        </div>
                                    </div>
                                ) : (
                                    <input
                                        type="number"
                                        value={allocatedMarks[getCompositeKey("subquestion", subQuestion.id)]?.allocated_marks || 0}
                                        onChange={(e) => handleSubQuestionMarksChange(e, subQuestionIndex)}
                                        className="rubric-marks-input"
                                        placeholder={`Max: ${subQuestion.marks}`}
                                    />
                                )}

                            {/* Add a line between subquestions */}
                            {subQuestionIndex < question.sub_questions.length - 1 && (
                                <hr style={{margin: "10px 0", border: "0.5px solid #ccc"}}/>
                            )}
                        </div>
                    );
                }
            })}


            {/* Tooltip */}
            {tooltipVisible && (
                <div className="tooltip" style={tooltipStyles}>
                    {tooltipMessage}
                </div>
            )}
        </div>
    );
};

// Inline tooltip styles for fade-in and fade-out effect
const tooltipStyles = {
    position: "absolute",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#333",
    color: "#fff",
    padding: "8px",
    borderRadius: "4px",
    fontSize: "12px",
    opacity: 1,
    transition: "opacity 0.3s ease-in-out",
    zIndex: 999,
};

export default Rubric;