import React, { useState } from "react";
import { Card, CardContent, Typography, RadioGroup, FormControlLabel, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import EditorComponent from '../../../components/Common/Quill/quillEditor';
import DisplayContent from '../../../components/displayContent/displayContent';
import QuestionResultsAPIService from "../../../services/api/QuestionResultsAPIService"; // Question results service
import TopicResultsApiService from "../../../services/api/TopicResultsAPIService";

const LearnerPerformanceTable = ({ topics, questions, cognitiveLevels, onViewChange }) => {
  const [viewType, setViewType] = useState("questions");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Items per page

  // Retrieve user role and token
  const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
  const token = userInfo.token || ''; // Assuming the token is available in userInfo

  // Modal states
  const [openRecommendationsModal, setOpenRecommendationsModal] = useState(false);
  const [openCommentsModal, setOpenCommentsModal] = useState(false);

  // Edit states
  const [editingRecommendations, setEditingRecommendations] = useState(false);
  const [editingComments, setEditingComments] = useState(false);

  // Content states
  const [modalContent, setModalContent] = useState("");
  const [editContent, setEditContent] = useState("");
  const [currentId, setCurrentId] = useState(null);

  // State to track local updates for recommendations and comments
  const [localUpdates, setLocalUpdates] = useState({});

  const handleViewChange = (event) => {
    setViewType(event.target.value);
    onViewChange(event.target.value);
    setCurrentPage(0);
  };

  // Function to determine the header title based on the selected view
  const headerTitle = (() => {
    switch (viewType) {
      case "questions":
        return "Questions";
      case "cognitiveLevels":
        return "Cognitive Levels";
      case "topics":
        return "Topics";
      default:
        return "Items";
    }
  })();

  // Function to render rows based on the selected view type
const renderRows = () => {
  let dataToDisplay = [];

  switch (viewType) {
    case "questions":
      dataToDisplay = questions.map((question, index) => ({
        name: `Question ${index + 1}`,
        percentage: question.percentage,
        markObtained : question.marks_obtained,
        possibleMarks: question.total_marks,
        recommendations: localUpdates[question.question_result_id]?.recommendations || question.recommendations,
        comment: localUpdates[question.question_result_id]?.comment || question.comment,
        id: question.question_result_id,
        type: "question"
      }));
      break;
    case "cognitiveLevels":
      dataToDisplay = Object.entries(cognitiveLevels).map(([key, value]) => ({
        name: value.level_name,
        percentage: value.percentage,
          markObtained : value.marks_obtained,
        possibleMarks: value.total_marks,
      }));
      break;
    case "topics":
      dataToDisplay = topics.map((topic) => ({
        name: topic.topic_name,
        percentage: topic.percentage,
        recommendations: localUpdates[topic.topic_results_id]?.recommendations || topic.recommendations,
        comment: localUpdates[topic.topic_results_id]?.comment || topic.comment,
        id: topic.topic_results_id,
        type: "topic",
        markObtained : topic.marks_obtained,
        possibleMarks: topic.total_marks,
      }));
      break;
    default:
      break;
  }

  // Sort data from largest to smallest percentage
  dataToDisplay.sort((a, b) => b.percentage - a.percentage);

  // Slice the data to only return the current page items
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = dataToDisplay.slice(startIndex, endIndex);

  return paginatedData.map((item, index) => (
    <TableRow key={index}>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        {item.name}
      </TableCell>
      <TableCell>
        {item.markObtained} / {item.possibleMarks} ({item.percentage}%)
      </TableCell>
    </TableRow>
  ));
};

  // Pagination handlers
  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < (viewType === "questions" ? questions.length : viewType === "cognitiveLevels" ? Object.entries(cognitiveLevels).length : topics.length)) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  // Function to handle saving of edited recommendations or comments for questions or topics
  const handleSave = async (type, resultType) => {
    if (currentId && token) {
      try {
        const updatedData = {};
        if (type === 'recommendations') {
          updatedData.recommendations = editContent;
        } else if (type === 'comment') {
          updatedData.comment = editContent;
        }

        // Call the appropriate API based on resultType (question or topic)
        if (resultType === 'question') {
          await QuestionResultsAPIService.updateQuestionResult(currentId, updatedData, token);
        } else if (resultType === 'topic') {
          await TopicResultsApiService.updateTopicResult(currentId, updatedData, token);
        }

        // Save the updated recommendations or comments locally
        setLocalUpdates((prevUpdates) => ({
          ...prevUpdates,
          [currentId]: {
            ...prevUpdates[currentId],
            [type]: editContent,
          }
        }));

        // After saving, close the modal and reset states
        if (type === 'recommendations') {
          setOpenRecommendationsModal(false);
        } else if (type === 'comment') {
          setOpenCommentsModal(false);
        }
      } catch (error) {
        console.error(`Failed to update result (${type}, Type: ${resultType}):`, error);
      }
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Learner Performance
          </Typography>

          <RadioGroup row value={viewType} onChange={handleViewChange}>
            <FormControlLabel value="questions" control={<Radio />} label="Questions" />
            <FormControlLabel value="cognitiveLevels" control={<Radio />} label="Cognitive Levels" />
            <FormControlLabel value="topics" control={<Radio />} label="Topics" />
          </RadioGroup>

          <TableContainer
            component={Paper}
            elevation={0} // Remove shadow
            sx={{ mt: 2, width: 'auto', boxShadow: 'none' }} // Fit content and remove shadow
          >
            <Table sx={{ borderCollapse: 'collapse' }}>
             <TableHead>
  <TableRow sx={{ backgroundColor: '#5F9EA0' }}>
    <TableCell sx={{ border: '1px solid black' }}>{headerTitle}</TableCell>
    <TableCell sx={{ border: '1px solid black' }}>Marks (Obtained / Total) and %</TableCell>
  </TableRow>
</TableHead>

              <TableBody>
                {renderRows()}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination Controls */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <Button onClick={handlePreviousPage} disabled={currentPage === 0}>
              Previous
            </Button>
            <Button onClick={handleNextPage} disabled={(currentPage + 1) * itemsPerPage >= (viewType === "questions" ? questions.length : viewType === "cognitiveLevels" ? Object.entries(cognitiveLevels).length : topics.length)}>
              Next
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* Recommendations Modal */}
      <Dialog open={openRecommendationsModal} onClose={() => setOpenRecommendationsModal(false)} fullWidth maxWidth="lg"> {/* Large modal */}
        <DialogTitle>Recommendations</DialogTitle>
        <DialogContent>
          {editingRecommendations ? (
            <EditorComponent
              initialData={editContent}
              onChangeReference={setEditContent}
              placeholder="Edit recommendations here..."
            />
          ) : (
            <DisplayContent content={modalContent || "No recommendations available."} />
          )}
        </DialogContent>
        <DialogActions>
          {editingRecommendations ? (
            <Button onClick={() => handleSave('recommendations', viewType === 'questions' ? 'question' : 'topic')} color="primary">
              Save
            </Button>
          ) : (
            <Button onClick={() => setEditingRecommendations(true)} color="primary">
              Edit
            </Button>
          )}
          <Button onClick={() => setOpenRecommendationsModal(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Comments Modal */}
      <Dialog open={openCommentsModal} onClose={() => setOpenCommentsModal(false)} fullWidth maxWidth="lg"> {/* Large modal */}
        <DialogTitle>Comments</DialogTitle>
        <DialogContent>
          {editingComments ? (
            <EditorComponent
              initialData={editContent}
              onChangeReference={setEditContent}
              placeholder="Edit comments here..."
            />
          ) : (
            <DisplayContent content={modalContent || "No comment available."} />
          )}
        </DialogContent>
        <DialogActions>
          {editingComments ? (
            <Button onClick={() => handleSave('comment', viewType === 'questions' ? 'question' : 'topic')} color="primary">
              Save
            </Button>
          ) : (
            <Button onClick={() => setEditingComments(true)} color="primary">
              Edit
            </Button>
          )}
          <Button onClick={() => setOpenCommentsModal(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LearnerPerformanceTable;
