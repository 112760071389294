import React, {useCallback, useEffect, useRef, useState} from "react";
import {Box, Drawer, IconButton, Typography} from "@mui/material";
import Question from "../Question/question";
import InsertQuestionComponent from "../InsertQuestionComponent/InsertQuestionComponent";
import Instructions from "../Instructions/Instructions";
import {generatePDF} from '../../../services/generatePDF';
import CreateQuestionComponent from '../../../features/createQuestion';
import UpdateQuestionComponent from "../../../features/createQuestion/updateQuestion";
import ExamCover from "../coverPage/ExamCover";
import AssessmentHeader from "../../../features/CreateAssessmentWizard/AssessmentHeader";
import SelectTopics from "../../../features/CreateAssessmentWizard/selectTopics";
import QuestionBank from "../questionBank/questionBank";
import CloseIcon from "@mui/icons-material/Close";
import "./assessmentPaper.css";
import AssessmentAPIService from "../../../services/api/AssessmentAPIService";
import {initialQuestionState} from "../../../data/constants/initialQuestionState";

const PAGE_HEIGHT = 1600;

const AssessmentPaper = ({
                             onEnter,
                             onLeave,
                             examId,
                             name,
                             subjectId,
                             subjectName,
                             grade,
                             date,
                             initialQuestions = [],
                             instructions
                         }) => {
    const [questions, setQuestions] = useState(Array.isArray(initialQuestions) ? initialQuestions : []);
    const [totalMarks, setTotalMarks] = useState(0);

    const [questionHeights] = useState({});
    const [numberOfPages, setNumberOfPages] = useState(1);
    const containerRef = useRef(null);
    const [selectedTopicIds, setSelectedTopicIds] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const initialLoad = useRef(true);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    // States for controlling component visibility and memo display
    const [hideComponents, setHideComponents] = useState(true);
    const [showMemo, setShowMemo] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isCreateQuestion, setIsCreateQuestion] = useState(false)
    const [accHeight, setAccHeight] = useState(0);

    // Calculate total marks based on questions
    const calculateMarks = () => {
        let total_marks = 0;
        for (const question of questions) {
            if (question.sub_questions && question.sub_questions.length > 0) {
                for (const subQuestion of question.sub_questions) {
                    total_marks += parseFloat(subQuestion.marks) || 0;
                    if (subQuestion.nested_sub_questions && subQuestion.nested_sub_questions.length > 0) {
                        for (const nestedSubQuestion of subQuestion.nested_sub_questions) {
                            total_marks += parseFloat(nestedSubQuestion.marks) || 0;
                        }
                    }
                }
            } else {
                total_marks += parseFloat(question.marks) || 0;
            }
        }
            return total_marks % 1 === 0 ? parseInt(total_marks, 10) : total_marks;
    };

// Update total marks whenever questions change
    useEffect(() => {
        const newTotalMarks = calculateMarks();
        setTotalMarks(newTotalMarks);
    }, [questions]);

    // Use `useEffect` to update the exam only after initial load
    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false; // Skip the initial load
        } else {
            handleUpdateExamPaper(); // Update only after initial load
        }
    }, [questions]); // Trigger only when `questions` changes after the initial load


    useEffect(() => {
        if (accHeight > PAGE_HEIGHT) {
            setAccHeight(0);
            setNumberOfPages(prev => prev + 1);
        }
    }, [accHeight]);

    const updateHeights = (newHeight) => {
        setAccHeight(accHeight + newHeight);
    };

    const combinedRef = (element) => {
        containerRef.current = element;
    };

    const handleExportWithAnswers = () => logHtmlString(true);
    const handleExport = () => logHtmlString(false);

    // Function to toggle memo visibility, to be passed to `SelectTopics`
    const toggleMemoVisibility = (isVisible) => {
        setShowMemo(isVisible);
    };

    // Function to update the exam paper on the backend
    const handleUpdateExamPaper = async () => {

        try {
            // Prepare the data for the backend
            const questionOrder = questions.map((question) => question.id);
            const updatedData = {
                questions: questionOrder,
                instructions: instructions,
                total_marks: calculateMarks(),
                name: name,
            };

            const userInfo = localStorage.getItem("user");
            const parsedUserInfo = JSON.parse(userInfo || "{}");
            const {token} = parsedUserInfo;

            const response = await AssessmentAPIService.updateExam(examId, updatedData, token);
            if (response) {
                console.log("Exam paper updated successfully.");
            } else {
                console.error("Failed to update exam paper.");
            }
        } catch (error) {
            console.error("Error updating exam paper:", error);
        }
    };

    const logHtmlString = useCallback(async (withAnswers = false) => {
        if (containerRef.current && questions.length > 0) {
            try {
                await handleUpdateExamPaper();
                setShowMemo(withAnswers);
                setHideComponents(false);

                setTimeout(() => {
                    const htmlString = containerRef.current.outerHTML;
                    generatePDF(htmlString, withAnswers ? `${name}-with-answers` : name);
                    setHideComponents(true);
                }, 1);
            } catch (error) {
                console.error("Error in updating exam paper or generating PDF:", error);
            }
        }
    }, [containerRef, name, questions, instructions]);

    // Function to add a question to the end of the assessment paper
    const addQuestionToAssessmentPaper = (question) => {
        setQuestions((prevQuestions) => {
            return [...prevQuestions, question];
        });
    };

    const onCreateQuestion = (isCreateQuestion, index) => {
        setIsCreateQuestion(isCreateQuestion);
        setCurrentQuestionIndex(index);
        setQuestions((prevItems) => {
            if (index >= prevItems.length) {
                return [...prevItems, initialQuestionState]
            } else {
                return [
                    ...prevItems.slice(0, index),
                    initialQuestionState,
                    ...prevItems.slice(index)
                ];
            }
        });
    }

    const onRemoveQuestion = (index) => {
        setQuestions((prevQuestions) => {
            return prevQuestions.filter((_, i) => i !== index);
        });
    };


    // Handle opening the question bank drawer with selected topic IDs
    const onOpenQuestionBank = (topicIds) => {
        setSelectedTopicIds(topicIds);
        setIsDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false); // Close the drawer
    };


    const OnAddQuestion = (question, index) => {
        setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[index] = question; // Insert question at the specified index
            return updatedQuestions;
        });
        setCurrentQuestionIndex(0);
        setIsCreateQuestion(false);
    };

    const OnAddInitialQuestion = (question, index) => {
        setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions.splice(index, 0, question); // Insert question at the specified index
            return updatedQuestions;
        });
    };


    // Move a question up in the assessment paper
    const moveQuestionUpInAssessmentPaper = (index) => {
        if (index > 0) {
            setQuestions((prevQuestions) => {
                const updatedQuestions = [...prevQuestions];
                [updatedQuestions[index - 1], updatedQuestions[index]] = [updatedQuestions[index], updatedQuestions[index - 1]];
                return updatedQuestions;
            });
        }
    };

    const moveQuestionDownInAssessmentPaper = (index) => {
        if (index < questions.length - 1) {
            setQuestions((prevQuestions) => {
                const updatedQuestions = [...prevQuestions];
                [updatedQuestions[index], updatedQuestions[index + 1]] = [updatedQuestions[index + 1], updatedQuestions[index]];
                return updatedQuestions;
            });
        }
    };

    const removeQuestionFromAssessmentPaper = (index) => {
        setQuestions((prevQuestions) => {
            return prevQuestions.filter((_, i) => i !== index);
        });
    };


    // Function to update a specific question in the questions array
    const updateQuestionOnAssessmentPaper = (index, updatedQuestion) => {
        setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            // Update the question at the specified index
            updatedQuestions[index] = updatedQuestion;
            return updatedQuestions;
        });

        setIsEdit(false);
    };


    const handleEditQuestion = (index) => {
        setIsEdit(true)
        setCurrentQuestionIndex(index)
    }

    const handleDiscardUpdate = () => {
        setIsEdit(false)
    }

    return (
        <div className="assessment-paper-wrapper">
            <AssessmentHeader
                name={name}
                onDownloadWithAnswers={handleExportWithAnswers}
                onDownload={handleExport}
            />

            {/* Select Topics Section */}
            <SelectTopics
                grade={grade}
                subjectId={subjectId}
                subject_name={name}
                onOpenQuestionBank={onOpenQuestionBank}
                toggleMemoVisibility={toggleMemoVisibility}
            />

            <div
                className={'assessment-paper-document expanded'}
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
                ref={combinedRef}
            >
                <div>
                    <ExamCover
                        testName={name}
                        grade={grade}
                        subjectName={subjectName}
                        date={date}
                        totalMarks={totalMarks}
                    />

                    {instructions && <Instructions instructions={instructions} hideComponents={hideComponents}/>}
                </div>

                {Array.from({length: numberOfPages}).map((_, pageIndex) => (
                    <React.Fragment key={pageIndex}>
                        <div className="assessment-paper-page">
                            {questions.length === 0 ? (
                                hideComponents &&
                                <InsertQuestionComponent
                                    index={0}
                                    onCreateQuestion={onCreateQuestion}
                                    hideComponents={hideComponents}
                                    selectedTopicIds={selectedTopicIds}
                                    onOpenQuestionsBank={onOpenQuestionBank}
                                    onOpenQuestionBank={onOpenQuestionBank}/>
                            ) : (
                                <div>
                                    {hideComponents &&
                                        <InsertQuestionComponent
                                            index={0}
                                            onCreateQuestion={onCreateQuestion}
                                            hideComponents={hideComponents}
                                            selectedTopicIds={selectedTopicIds}
                                            onOpenQuestionsBank={onOpenQuestionBank}
                                        />
                                    }
                                    {questions.map((question, index) => (
                                        <Box mt={2} mb={2} key={question.id} style={{
                                            marginTop: `${questionHeights[question.id] || 0}px`,
                                            transition: 'margin-top 0.3s ease-in-out'
                                        }}>
                                            {(() => {
                                                if (isCreateQuestion && currentQuestionIndex === index) {
                                                    return <CreateQuestionComponent
                                                        index={currentQuestionIndex}
                                                        emptyQuestion={question}
                                                        createNewQuestion={false}
                                                        OnAddQuestion={OnAddQuestion}
                                                        setIsCreateQuestion={setIsCreateQuestion}
                                                        OnAddInitialQuesiton={OnAddInitialQuestion}
                                                        grade={grade}
                                                        subjectId={subjectId}
                                                        removeQuestionFromAssessmentPaper={removeQuestionFromAssessmentPaper}
                                                    />;
                                                } else if (isEdit === true && currentQuestionIndex === index) {
                                                    return <UpdateQuestionComponent
                                                        initialQuestion={question}
                                                        onRemoveQuestion={onRemoveQuestion}
                                                        index={index}
                                                        grade={grade}
                                                        subject={subjectId}
                                                        handleDiscardUpdate={handleDiscardUpdate}
                                                        updateQuestionOnAssessmentPaper={updateQuestionOnAssessmentPaper}
                                                    />;
                                                } else {
                                                    return (
                                                        <Question
                                                            index={index}
                                                            question={question}
                                                            isAddedToAssessment={true}
                                                            isDraggable={false}
                                                            onUpdateHeight={updateHeights}
                                                            showAddFromQuestionBank={true}
                                                            showMemo={showMemo}
                                                            moveQuestionUpInAssessmentPaper={moveQuestionUpInAssessmentPaper}
                                                            moveQuestionDownInAssessmentPaper={moveQuestionDownInAssessmentPaper}
                                                            onRemoveQuestion={onRemoveQuestion}
                                                            hideComponents={hideComponents}
                                                            showTags={hideComponents}
                                                            handleEditQuestion={handleEditQuestion}
                                                        />
                                                    );
                                                }
                                            })()}

                                            {index < questions.length - 1 && hideComponents &&
                                                <InsertQuestionComponent
                                                    index={index + 1}
                                                    onCreateQuestion={onCreateQuestion}
                                                    setIsCreateQuestion={setIsCreateQuestion}
                                                    hideComponents={hideComponents}
                                                    selectedTopicIds={selectedTopicIds}
                                                    onOpenQuestionsBank={onOpenQuestionBank}

                                                />}
                                        </Box>
                                    ))}
                                    {hideComponents &&
                                        <InsertQuestionComponent
                                        index={questions.length}
                                        onCreateQuestion={onCreateQuestion}
                                        setIsCreateQuestion={setIsCreateQuestion}
                                        hideComponents={hideComponents}
                                        selectedTopicIds={selectedTopicIds}
                                        onOpenQuestionsBank={onOpenQuestionBank}

                                    />}

                                </div>
                            )}
                        </div>
                        {pageIndex !== numberOfPages - 1 && <div className="grey-space"></div>}
                    </React.Fragment>
                ))}
            </div>

            {/* Drawer for Question Bank */}
            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
                PaperProps={{
                    sx: {width: '55%'} // Adjust width as needed
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between" p={2}
                     borderBottom="1px solid #ddd">
                    <Typography variant="h6">Question Bank</Typography>
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon/>
                    </IconButton>
                </Box>

                <Box p={2}>
                    {/* Pass selectedTopicIds, grade, and subjectId to QuestionBank */}
                    <QuestionBank
                        topicIds={selectedTopicIds}
                        grade={grade}
                        addQuestionToAssessmentPaper={addQuestionToAssessmentPaper}
                        subjectId={subjectId}
                        questions={questions}/>
                </Box>
            </Drawer>
        </div>
    );
};

export default AssessmentPaper;
