import React from "react";
import { Chip, Box, IconButton, Button, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";

const QuestionSelector = ({
                              questions,
                              selectedQuestionIndex,
                              setSelectedQuestionIndex,
                              onHomeClick,
                              onSubmitMarking,
                              grade,
                              subject,
                              assessmentName,
                              firstName,
                              lastName,
      totalAllocatedMarks, // Pass this as a prop
    totalPossibleMarks, // Pass this as a prop
}) => {

    const navigate = useNavigate(); // Hook for navigation


    return (
        <Box
            className="question-selector"
            padding={2}
            borderBottom="1px solid #ccc"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
        >
            {/* Add Home Button, Arrow, and Learner Info on the left */}
            <Box display="flex" alignItems="center" marginRight="16px" height="100%">
                {/* Home Button */}
                <IconButton onClick={onHomeClick} style={{ marginRight: "8px" }}>
                    <HomeIcon style={{ fontSize: 25, color: "#5F9EA0" }} /> {/* Smaller home icon */}
                </IconButton>

                {/* Chevron Arrow */}
                <IconButton onClick={() => navigate(-1)} style={{ marginRight: "4px" }}> {/* Add onClick for navigation */}
                    <ChevronRightIcon style={{ fontSize: 30, color: "#B0B0B0", height: "100%" }} /> {/* Taller arrow */}
                </IconButton>

                {/* Learner Information */}
                <Box display="flex" flexDirection="column" marginLeft="16px">
                    <Typography variant="subtitle1" fontWeight="bold">
                        {firstName} {lastName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {assessmentName} - Grade {grade} - {subject}
                    </Typography>
                </Box>
            </Box>

            {/* Render Question Chips in the center */}
            <Box display="flex" justifyContent="center" flexWrap="wrap" flexGrow={1}>
                {questions.map((question, index) => (
                    <Chip
                        key={index}
                        label={`Q${index + 1}`}
                        clickable
                        color={selectedQuestionIndex === index ? "primary" : "default"}
                        onClick={() => setSelectedQuestionIndex(index)}
                        style={{ margin: "0 8px 8px 0" }}
                    />
                ))}
            </Box>

                <Typography variant="body1" fontWeight="bold" style={{ marginBottom: "4px" }}>
                    Total Marks: {totalAllocatedMarks} / {totalPossibleMarks}
                </Typography>


            {/* Submit Marking Button */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmitMarking}
                    style={{ marginLeft: "16px", backgroundColor: "#5F9EA0" }}
                >
                    Submit Marking
                </Button>
        </Box>
    );
};

export default QuestionSelector;
