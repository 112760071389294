import React, { useEffect, useState } from "react";
import {
    Grid,
    Container,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Card,
    CircularProgress,
    useMediaQuery
} from "@mui/material";
import SummaryCards from "./SummaryCard/SummaryCards";
import CourseAssessmentResultsApiService from "../../services/api/CourseAssessmentResultsApiService";
import { useNavigate, useParams } from "react-router-dom";
import ClassPerformanceStats from "./LearnerPerformanceStats/ClassPerformanceStats";
import LearnerResultAnalysis from "./LearnerResultAnalysis/LearnerResultAnalysis";
import BreadcrumbNavigation from "./BreadcrumbNavigation";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import HomeIcon from "@mui/icons-material/Home";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LearnerPerformanceCard from "./LearnerPerformanceCard/LearnerPerformanceCard";
import DisplayContent from "../../components/displayContent/displayContent";
import EditorComponent from "../../components/Common/Quill/quillEditor";


const StatsPage = () => {
  const { cAId } = useParams();
  const [courseAssessmentResults, setCourseAssessmentResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [learnerFilter, setLearnerFilter] = useState("all");
  const [selectedLearner, setSelectedLearner] = useState(null);
  const [currentView, setCurrentView] = useState("summary");

  // AI Analysis Modal States
  const [openAIModal, setOpenAIModal] = useState(false);
  const [editingAI, setEditingAI] = useState(false);
  const [aiAnalysisContent, setAiAnalysisContent] = useState("");

  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");
  const { token, user_role } = parsedUserInfo;
  const navigate = useNavigate();

  // Media query to detect mobile screen size
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (cAId && token) {
      CourseAssessmentResultsApiService.getDetailedResults(cAId, token)
        .then((response) => {
          if (response) {
            setCourseAssessmentResults(response);
            setAiAnalysisContent(response.ai_analysis || "");
          } else {
            console.log("Response is empty or invalid:", response);
            setCourseAssessmentResults(null);
          }
        })
        .catch((err) => {
          console.error("Error fetching detailed results:", err.message);
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [cAId, token]);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const {
    averages,
    submitted,
    learners_passed,
    learners_failed,
    learners,
    not_attempted,
    questions,
    topics,
    total_learners,
    cognitive_levels,
    assessment_name,
    grade,
    subject,
    pass_mark,
  } = courseAssessmentResults;

  console.log("pass_mark", pass_mark)

  const handleRowClick = (clickedLearner, filter) => {
    const fullLearnerData = learners.find(
      (learner) =>
        learner.learner.first_name === clickedLearner.first_name &&
        learner.learner.last_name === clickedLearner.last_name
    );

    if (fullLearnerData) {
      setLearnerFilter(filter);
      setSelectedLearner(fullLearnerData);
      setCurrentView("learner");
    } else {
      console.error("Learner not found in the array");
    }
  };

  const handleSaveAI = async () => {
    try {
      if (user_role) {
        await CourseAssessmentResultsApiService.updateAIAnalysis(cAId, aiAnalysisContent, token);
        setEditingAI(false);
        handleCloseAIModal();
      } else {
        console.error("You do not have permission to update AI analysis.");
      }
    } catch (error) {
      console.error("Error saving AI analysis:", error);
    }
  };

  const handleOpenAIModal = () => setOpenAIModal(true);
  const handleCloseAIModal = () => {
    setOpenAIModal(false);
    setEditingAI(false);
  };

  const handleEditAI = () => setEditingAI(true);

  return (
    <Box sx={{ bgcolor: "#dce4e6", minHeight: "100vh", padding: 0 }}>
      <Container maxWidth={false} disableGutters>
        <Card
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            boxShadow: "none",
            borderBottom: "1px solid #ccc"
          }}
        >
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => navigate("/home")}>
              <HomeIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => navigate(-1)}>
              <ChevronRightIcon color="primary" />
            </IconButton>
            <BreadcrumbNavigation
              assessmentName={assessment_name}
              grade={grade}
              subject={subject}
              learnerName={
                selectedLearner
                  ? `${selectedLearner.learner.first_name} ${selectedLearner.learner.last_name}`
                  : null
              }
              onBackToSummaryClick={() => setCurrentView("summary")}
            />
          </Box>
          <Button
  variant="outlined"
  startIcon={<AutoAwesomeIcon />}
  onClick={handleOpenAIModal}
  sx={{
    backgroundColor: "#FFFFFF",
    borderColor: "#5F9EA0",
    color: "#5F9EA0",
    borderRadius: "24px",
    textTransform: "none",
    padding: "6px 16px",
    boxShadow: "none",
    whiteSpace: "nowrap", // Prevent text from wrapping
    fontSize: 'clamp(0.5rem, 1vw + 0.5rem, 1rem)', // Responsive font size
    minWidth: "100px", // Minimum width to ensure enough space
    mr: 0.5, // Margin right
    "&:hover": {
      backgroundColor: "#F0F0F0",
      borderColor: "#5F9EA0",
      boxShadow: "none",
    },
  }}
>
  AI Analysis
</Button>

        </Card>

        {/* Main Content Layout */}
        {isMobile ? (
          <LearnerPerformanceCard
            allLearners={learners}
            onLearnerClick={handleRowClick}
            sx={{ width: "100%" }}
            isMobile={isMobile}
            passMark={pass_mark || 40}
          />
        ) : currentView === "learner" && selectedLearner ? (
          // Render LearnerResultAnalysis if a learner is selected
          <LearnerResultAnalysis
            learnerData={selectedLearner}
            grade={grade}
            subject={subject}
            assessment_name={assessment_name}
            totalLearners={total_learners}
            classAverage={averages[0].average_mark}
            passMark={pass_mark || 40}
          />
        ) : (
          // Render summary view if no learner is selected (non-mobile screens only)
          <Grid container spacing={2} sx={{ margin: 0, width: "100%" }}>
            <Grid item xs={12} md={3}>
              <LearnerPerformanceCard
                      averages={averages} // Pass all averages
                topLearners={learners
                  .sort((a, b) => b.learner.mark - a.learner.mark)
                  .slice(0, 4)}
                bottomLearners={learners
                  .sort((a, b) => a.learner.mark - b.learner.mark)
                  .slice(0, 4)}
                allLearners={learners}
                onLearnerClick={handleRowClick}
                sx={{ width: "100%" }}
                isMobile={isMobile} // Pass isMobile to LearnerPerformanceCard
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Box sx={{ mb: 2, width: "100%" }}>
                <SummaryCards
                  averageMark={averages[0].average_mark}             // Latest average mark
                  previousAverageMark={averages[1]?.average_mark}
                  totalLearners={total_learners}
                  totalSubmitted={submitted}
                  totalPassed={learners_passed.learners.length}
                  totalFailedWithNonSubmit={learners_failed.learners.length + not_attempted.number}
                  passedPercentage={((learners_passed.learners.length / total_learners) * 100).toFixed(2)}
                  failedPercentage={(((learners_failed.learners.length + not_attempted.number) / total_learners) * 100).toFixed(2)}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <ClassPerformanceStats
                  questions={questions}
                  topics={topics}
                  cognitiveLevels={Object.values(cognitive_levels)}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>

      {/* AI Analysis Modal */}
      <Dialog open={openAIModal} onClose={handleCloseAIModal} fullWidth maxWidth="lg">
        <DialogTitle>AI Analysis</DialogTitle>
        <DialogContent>
          {editingAI ? (
            <EditorComponent
              initialData={aiAnalysisContent}
              onChangeReference={setAiAnalysisContent}
              placeholder="Edit AI analysis here..."
            />
          ) : (
            <DisplayContent content={aiAnalysisContent || "No AI analysis available."} />
          )}
        </DialogContent>
        <DialogActions>
          {editingAI ? (
            <Button onClick={handleSaveAI} color="primary">Save</Button>
          ) : (
            <Button onClick={handleEditAI} color="primary">Edit</Button>
          )}
          <Button onClick={handleCloseAIModal} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StatsPage;
