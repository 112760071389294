import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { OnlineTestApiService } from "../../../../services/api/OnlineTestAPIService";
import CourseAssessmentResultsApiService from "../../../../services/api/CourseAssessmentResultsApiService";

const ManualMarkingClassList = () => {
    const { examId, courseId, cAId } = useParams();
    const [learners, setLearners] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;

    const pageSize = 15; // Set default page size

    useEffect(() => {
        const fetchLearners = async () => {
            try {
                const response = await OnlineTestApiService.fetchOnlineTestsByExamId(examId, courseId, token);
                if (response) {
                    setLearners(response.learners);
                } else {
                    console.error("Failed to fetch learners.");
                }
            } catch (error) {
                console.error("Error fetching learners:", error);
            }
        };

        fetchLearners();
    }, [examId, courseId, token]);

    const handleRowClick = (params) => {
        if (params.row.attempted) {
            const onlineTestId = params.row.online_test_id;
            navigate(`/manual-marking/marking/${onlineTestId}/${examId}/${cAId}`, {
                state: {
                    learnerFirstName: params.row.first_name,
                    learnerLastName: params.row.last_name,
                },
            });
        }
    };

    const handleDoneMarking = async () => {
        try {
            const response = await CourseAssessmentResultsApiService.updateAssessmentStats(cAId, token);
            if (response) {
                console.log("Assessment stats updated successfully:", response);
            }
        } catch (error) {
            console.error("Failed to update assessment stats:", error);
        }
    };

    // Calculate learner statistics
    const completedCount = learners.filter((learner) => learner.status.toLowerCase() === "completed").length;
    const notCompletedCount = learners.filter((learner) => learner.status.toLowerCase() === "incomplete").length;
    const notAttemptedCount = learners.filter((learner) => !learner.attempted).length;

    const columns = [
        {
            field: "name",
            headerName: "Learner Name",
            flex: 2,
            align: "left",
            renderCell: (params) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span>{`${params.row.first_name} ${params.row.last_name}`}</span>
                </div>
            ),
        },
        {
            field: "attempted",
            headerName: "Attempted",
            flex: 1,
            align: "left",
            renderCell: (params) => <span>{params.row.attempted ? "Yes" : "No"}</span>,
        },
        {
            field: "status",
            headerName: "Marking Status",
            flex: 1,
            align: "left",
        },
    ];

    const filteredLearners = learners
        .filter(
            (learner) =>
                learner.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                learner.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                learner.status.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            // Sort by marking status: Incomplete first
            const statusPriority = { incomplete: 0, completed: 1 };
            return statusPriority[a.status.toLowerCase()] - statusPriority[b.status.toLowerCase()];
        });

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px", padding: "20px" }}>

                {/* Display counts of completed, not completed, and not attempted learners */}
                <div style={{ marginBottom: "10px", fontSize: "16px", fontWeight: "bold" }}>
                    Marking Status: {learners.length - completedCount} learners left, {completedCount} marked, {notAttemptedCount} not attempted
                </div>

                {/* Inner flexbox (row layout for button on left and search on right) */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "20px",
                    }}
                >
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ borderRadius: "25px", backgroundColor: "#5f9ea0" }}
                        onClick={handleDoneMarking}
                    >
                        Done Marking
                    </Button>

                    <TextField
                        id="search"
                        placeholder="Search Learners"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        variant="outlined"
                        InputProps={{
                            style: { width: "200px", borderRadius: 25 },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "25px",
                                borderColor: "#d0d4d9",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#d0d4d9",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#b3b3b3",
                            },
                            "& .MuiInputBase-input": {
                                padding: "10px",
                                color: "#5f9ea0",
                            },
                        }}
                    />
                </div>

                {/* Table is below the search, class info, and button */}
                <div style={{height: '35%', width: "100%"}}>
                    <DataGrid
                        rows={filteredLearners.map((learner) => ({...learner, id: learner.id}))}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[15, 30, 50]} // Add more options for pagination
                        pagination
                        disableSelectionOnClick
                        onRowClick={handleRowClick}
                        getRowClassName={(params) => (params.row.attempted ? "" : "greyed-out-row")}
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "10px",
                            border: "none",
                            "& .greyed-out-row": {
                                backgroundColor: "lightgrey",
                                pointerEvents: "none",
                            },
                        }}
                    />
                </div>

            </div>
        </>
    );
};

export default ManualMarkingClassList;
