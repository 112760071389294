import React, { useState } from "react";
import { Card, CardContent, Typography, Box, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import EditorComponent from "../../../components/Common/Quill/quillEditor";
import DisplayContent from "../../../components/displayContent/displayContent";
import { OnlineTestApiService } from "../../../services/api/OnlineTestAPIService";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const LearnerResultHeader = ({ firstName, lastName, mark, assessmentName, subject, grade, rank, totalLearners, comment: initialComment, classAverage, passMark, onlineTestId }) => {
  const [openAIAnalysisModal, setOpenAIAnalysisModal] = useState(false);
  const [editing, setEditing] = useState(false); // Overall editing state
  const [editMode, setEditMode] = useState("editor"); // "editor" or "input" mode
  const [comment, setComment] = useState(initialComment || "");

  // Retrieve user role and token
  const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
  const userRole = userInfo.user_role || '';
  const token = userInfo.token || '';

  // Function to get initials for the avatar
  const getInitials = () => {
    if (firstName && lastName) {
      return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
    }
    return "N/A";
  };

  // Function to format the rank with both emoji and ordinal suffix
  const formatRank = (rank) => {
    let rankSuffix;
    let emoji;

    switch (rank) {
      case 1:
        rankSuffix = "st";
        emoji = "🥇"; // Gold Medal
        break;
      case 2:
        rankSuffix = "nd";
        emoji = "🥈"; // Silver Medal
        break;
      case 3:
        rankSuffix = "rd";
        emoji = "🥉"; // Bronze Medal
        break;
      default:
        rankSuffix = "th";
        emoji = "🏅"; // General medal emoji
        break;
    }

    return `${rank}${rankSuffix} ${emoji}`;
  };

  // Indicate if the learner has failed
  const hasFailed = mark < passMark;

  // Handle saving of the comment
  const handleSave = async () => {
    try {
      await OnlineTestApiService.updateCommentsAndRecommendations(onlineTestId, comment, null, token);
      setEditing(false);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <>
      <Card>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: 2, marginRight: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingBottom: 2 }}>
            <Avatar sx={{ width: 56, height: 56, bgcolor: "#5F9EA0", marginRight: 2 }}>
              {getInitials()}
            </Avatar>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5">{`${firstName} ${lastName}`}</Typography>
              <Typography variant="body1">
                Mark: {mark}% {hasFailed && <span style={{ color: 'red' }}>(Failed)</span>}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Class Average: {classAverage}%
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: 'primary.main' }}
                  onClick={() => setOpenAIAnalysisModal(true)}
                >
                  <AutoAwesomeIcon sx={{ marginRight: 0.5, color: 'primary.main' }} />
                  AI Analysis
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginLeft: 'auto', textAlign: 'right' }}>
              <Typography variant="h6">
                Class Rank: {formatRank(rank)} {`(out of ${totalLearners})`}
              </Typography>
              <Typography variant="body2">{`Assessment: ${assessmentName || 'N/A'}`}</Typography>
              <Typography variant="body2">{`Subject: ${subject || 'N/A'}`}</Typography>
              <Typography variant="body2">{`Grade: ${grade || 'N/A'}`}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Dialog open={openAIAnalysisModal} onClose={() => setOpenAIAnalysisModal(false)} fullWidth maxWidth="sm">
        <DialogTitle>
          AI Analysis
          {userRole === "admin" && !editing && (
            <Button
              sx={{ marginLeft: 2 }}
              onClick={() => setEditing(true)}
              color="primary"
              size="small"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          )}
        </DialogTitle>
        <DialogContent>
          {editing ? (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Button
                  variant={editMode === "editor" ? "contained" : "outlined"}
                  onClick={() => setEditMode("editor")}
                >
                  Use Editor
                </Button>
                <Button
                  variant={editMode === "input" ? "contained" : "outlined"}
                  onClick={() => setEditMode("input")}
                >
                  Use Input Field
                </Button>
              </Box>
              {editMode === "editor" ? (
                <EditorComponent
                  initialData={comment}
                  onChangeReference={(value) => setComment(value)}
                  placeholder="Edit AI Analysis here"
                />
              ) : (
                <TextField
                  fullWidth
                  label="Enter Comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  multiline
                  rows={4}
                />
              )}
            </>
          ) : (
            <DisplayContent content={comment} />
          )}
        </DialogContent>
        <DialogActions>
          {editing ? (
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          ) : (
            <Button onClick={() => setOpenAIAnalysisModal(false)} color="primary">
              Close
            </Button>
          )}
          <Button onClick={() => setOpenAIAnalysisModal(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LearnerResultHeader;
