import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {OnlineTestApiService} from "../../../services/api/OnlineTestAPIService";
import {FeedbackApiService} from "../../../services/api/FeedbackApiService";
import Swal from "sweetalert2";
import AdobePDFViewer from "./MarkingMenu/AdobePDFViewer/AdobePDFViewer";
import Rubric from "./MarkingMenu/Rubric/Rubric";
import QuestionSelector from "./MarkingMenu/QuestionSelector/QuestionSelector";

const ManualMarking = () => {
    const [pdfUrl, setPdfUrl] = useState("");
    const [questions, setQuestions] = useState([]);
    const {examId, onlineTestId, cAId} = useParams();
    const [allocatedMarks, setAllocatedMarks] = useState({});
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
    const navigate = useNavigate();
    const [grade, setGrade] = useState("");
    const [subject, setSubject] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [assessmentName, setAssessmentName] = useState("");
    const [answerPdfUrl, setAnswerPdfUrl] = useState("");

    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const {token} = parsedUserInfo;

    // Function to handle mark changes
    const handleMarkChange = (id, type, marks) => {
        const key = `${type}_${id}`;
        setAllocatedMarks((prevState) => ({
            ...prevState,
            [key]: {type, allocated_marks: marks},
        }));
    };


    const handleSubmitMarking = async () => {
        const feedbackList = Object.keys(allocatedMarks).map(key => {
            // Extract the numeric ID from the composite key
            const questionId = key.split('_').pop();

            return {
                question_id: questionId,  // Use the numeric ID
                type: allocatedMarks[key].type,
                allocated_marks: allocatedMarks[key].allocated_marks
            };
        });

        try {
            const response = await FeedbackApiService.submitManualFeedback(onlineTestId, feedbackList, cAId, answerPdfUrl, token);
            if (response) {
                await Swal.fire("Success", "Manual feedback submitted successfully!", "success");
                navigate(-1);
            } else {
                await Swal.fire("Error", "Failed to submit manual feedback. Please try again.", "error");
            }
        } catch (error) {
            console.error("Error submitting manual feedback:", error);
            await Swal.fire("Error", "An error occurred while submitting feedback.", "error");
        }
    };

    const handleHomeClick = () => {
        // Navigate back to home or any other page
        navigate("/home");
    };

    useEffect(() => {
        const fetchImageUrls = async () => {
            try {
                const response = await OnlineTestApiService.fetchImageUrlsByOnlineTestId(onlineTestId, examId, token);
                if (response) {
                    setQuestions(response.questions);
                    setPdfUrl(response.pdf_url);
                    setGrade(response.grade);
                    setSubject(response.subject);
                    setFirstName(response.first_name);
                    setLastName(response.last_name);
                    setAssessmentName(response.exam_name);


                    // Initialize allocated marks from backend feedback
                    const initialMarks = {};

                    // Helper function to create a composite key
                    const getCompositeKey = (fb) => `${fb.question_type}_${fb.question_id}`;

                    // Loop through feedback to populate marks if available
                    response.feedback.forEach(fb => {

                        const key = getCompositeKey(fb);
                        if (fb.question_type === "nested_subquestion") {
                            initialMarks[key] = {
                                type: "nested_subquestion",
                                allocated_marks: fb.allocated_marks !== null ? fb.allocated_marks : 0,
                            };
                        } else if (fb.question_type === "subquestion") {
                            initialMarks[key] = {
                                type: "subquestion",
                                allocated_marks: fb.allocated_marks !== null ? fb.allocated_marks : 0,
                            };
                        } else {
                            initialMarks[key] = {
                                type: "question",
                                allocated_marks: fb.allocated_marks !== null ? fb.allocated_marks : 0,
                            };
                        }
                    });

                    // Ensure subquestions and nested questions are handled correctly
                    response.questions.forEach(question => {
                        const questionKey = `question_${question.id}`;

                        // If no feedback for question, initialize to 0 only if feedback wasn't already added
                        if (!initialMarks[questionKey]) {
                            initialMarks[questionKey] = {type: "question", allocated_marks: 0};
                        }

                        // Loop through subquestions and nested subquestions
                        question.sub_questions?.forEach(subQuestion => {
                            const subQuestionKey = `subquestion_${subQuestion.id}`;

                            if (!initialMarks[subQuestionKey]) {
                                initialMarks[subQuestionKey] = {type: "subquestion", allocated_marks: 0};
                            }

                            subQuestion.nested_sub_questions?.forEach(nestedSubQuestion => {
                                const nestedSubQuestionKey = `nested_subquestion_${nestedSubQuestion.id}`;

                                if (!initialMarks[nestedSubQuestionKey]) {
                                    initialMarks[nestedSubQuestionKey] = {
                                        type: "nested_subquestion",
                                        allocated_marks: 0
                                    };
                                }
                            });
                        });
                    });

                    setAllocatedMarks(initialMarks);
                } else {
                    console.error("Failed to fetch image URLs.");
                }
            } catch (error) {
                console.error("Error fetching image URLs:", error);
            }
        };

        fetchImageUrls();
    }, [examId, onlineTestId, token]);


    const handlePdfSaved = (url) => {
        console.log("storing the pdf url", url)
        setAnswerPdfUrl(url); // Store the saved PDF URL
        console.log("storing the pdf url", answerPdfUrl)

    };

    // Function to calculate total allocated marks
    const totalAllocatedMarks = Object.values(allocatedMarks).reduce(
        (sum, mark) => sum + (mark.allocated_marks || 0),
        0
    );

    // Function to calculate total possible marks
    const totalPossibleMarks = questions.reduce(
        (sum, question) => sum + (parseFloat(question.marks) || 0),
        0
    );


    return (
        <div className="manual-marking-container" style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
            {/* Question Selector Component */}
            <QuestionSelector
                questions={questions}
                selectedQuestionIndex={selectedQuestionIndex}
                setSelectedQuestionIndex={setSelectedQuestionIndex}
                onHomeClick={handleHomeClick} // Home click handler
                onSubmitMarking={handleSubmitMarking} // Submit marking handler
                grade={grade}
                subject={subject}
                firstName={firstName}
                lastName={lastName}
                assessmentName={assessmentName}
                totalAllocatedMarks={totalAllocatedMarks} // Pass calculated marks
                totalPossibleMarks={totalPossibleMarks}
            />

            {/* Main Content Area */}
            <div className="marking-content" style={{display: 'flex', flex: 1}}>
                {/* Left - Rubric */}
                <div className="rubric-section" style={{width: '40%', padding: '20px', borderRight: '1px solid #ccc'}}>
                    {questions[selectedQuestionIndex] && (
                        <Rubric
                            question={questions[selectedQuestionIndex]}
                            index={selectedQuestionIndex}
                            onMarkChange={handleMarkChange}
                            allocatedMarks={allocatedMarks}
                        />
                    )}
                </div>

                {/* Right - PDF Viewer */}
                <div className="pdf-viewer-section" style={{width: '60%', padding: '20px'}}>
                    <AdobePDFViewer pdfUrl={pdfUrl} onPdfSaved={handlePdfSaved}/></div>
            </div>
        </div>
    );
};

export default ManualMarking;
